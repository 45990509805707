import React, { useState, useEffect } from 'react';
import axios from 'axios';
import EquipementRow from './EquipementRow';

const EquipementsTable = ({ url }) => {
  const [refreshCallback, setRefreshCallback] = useState(null);
  const [equipements, setEquipements] = useState(null);
  const [selectedType, setSelectedType] = useState('tout');
  const [sortedColumn, setSortedColumn] = useState('nom');


  useEffect(() => {
    fetchEquipements();
  }, []);

  const fetchEquipements = async () => {
    try {
      const response = await axios.get(url);
      setEquipements(response.data);
    } catch (error) {
      console.error(`Erreur lors de la récupération des équipements : ${error.message}`);
    }
  };

  const handleModifierClick = (id) => {
    console.log(`Redirection vers la page de modification pour l'équipement avec l'ID ${id}`);
    // Ici, vous pouvez ajouter la logique pour rediriger vers la page de modification
  };

  const handleMettreAuRebusClick = (numeroSerie) => {
    console.log(`L'équipement avec le numéro de série ${numeroSerie} a été mis au rebus.`);
    // Ici, vous pouvez ajouter la logique pour marquer l'équipement comme au rebus
  };

  const handleSupprimerClick = async (id) => {
    const isConfirmed = window.confirm('Êtes-vous certain de vouloir supprimer cet équipement?');
    if (isConfirmed) {
      try {
        await axios.delete(`http://91.160.59.210:45677/equipements/${id}`);
        const updatedEquipements = equipements.filter((equipement) => equipement.id!== id);
        setEquipements(updatedEquipements);
      } catch (error) {
        console.error(`Error deleting equipement ${id}:`, error);
      }
    }
  };

  const handleRefresh = () => {
    //TODO  Actualiser la liste des équipements
    fetchEquipements()
  };
  const filterEquipementsByType = (type) => {
    if (type === 'tout') {
      return equipements;
    } else {
      return equipements.filter(equipement => equipement.type === type);
    }
  };

  const sortEquipementsByColumn = (column, order = 'asc', type = 'tout') => {
    const filteredEquipements = filterEquipementsByType(type);
    const sortedEquipements = [...filteredEquipements].sort((a, b) => {
      let aValue = String(a[column]);
      let bValue = String(b[column]);
  
      if (order === 'asc') {
        if (aValue < bValue) return -1;
        if (aValue > bValue) return 1;
        return 0;
      } else {
        if (aValue > bValue) return -1;
        if (aValue < bValue) return 1;
        return 0;
      }
    });
  
    return sortedEquipements;
  };

  const handleSortClick = (column) => {
    setSortedColumn(column);
  };

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };

  return (
    <div className="main-panel">
      <div className="filter-tabs">
        <button onClick={() => handleTypeChange({target: {value: 'tout'}})}>Tout</button>
        <button onClick={() => handleTypeChange({target: {value: 'Connecteur'}})}>Connecteur</button>
        <button onClick={() => handleTypeChange({target: {value: 'Dégaine'}})}>Dégaine</button>
        <button onClick={() => handleTypeChange({target: {value: 'Casque'}})}>Casque</button>
        <button onClick={() => handleTypeChange({target: {value: 'Harnais'}})}>Harnais</button>
        <button onClick={() => handleTypeChange({target: {value: 'Corde'}})}>Corde</button>
        <button onClick={() => handleTypeChange({target: {value: 'Assureur'}})}>Assureur</button>
      </div>
      <br/>
      <table>
        <thead>
            <tr>
            <th>Type équipement</th>
            <th>Num. Série</th>
            <th>Nom</th>
            <th>Commentaire</th>
            <th>Date de validité</th>
            <th>Date dernier événement</th>
            <th>Type dernier Événement</th>
            <th>Gestionnaire</th>
            <th>Actions</th>
            </tr>
        </thead>
        <tbody>
          {equipements && sortEquipementsByColumn('nom', 'asc', selectedType).map((equipement) => (
            <EquipementRow
              key={equipement.equipementId}
              equipement={equipement}
              onModifierClick={handleModifierClick}
              onMettreAuRebusClick={handleMettreAuRebusClick}
              onSupprimerClick={handleSupprimerClick}
              refreshCallback={handleRefresh}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EquipementsTable;