import { createContext, useContext, useState } from 'react';


const UserContext = createContext();
  

export const useUser = () => {
  return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
  // Récupérer les informations de l'utilisateur du localStorage au chargement de la page
  const [user, setUser] = useState(() => {
     const storedUser = localStorage.getItem('user');
     return storedUser ? JSON.parse(storedUser) : null;
  });
 
  const login = (userData) => {
     localStorage.setItem('user', JSON.stringify(userData));
     setUser(userData);
  };
 
  const logout = () => {
     localStorage.removeItem('user');
     setUser(null);
  };
 
  return (
     <UserContext.Provider value={{ user, login, logout }}>
       {children}
     </UserContext.Provider>
  );
 };
