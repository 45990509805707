// Importez les bibliothèques React nécessaires
import React, { useState } from 'react';
import '../user-management/Register.css'; // Assurez-vous de créer un fichier CSS pour le style
import { useNavigate } from 'react-router-dom';
// Ajoutez cette ligne en haut de votre fichier AddEpi.js
import { useUser } from '../user-management/UserContext';

// Définissez le composant AddEquipment
const AddEquipment = () => {
  // Utilisez le state pour suivre les valeurs des champs du formulaire
  const { user } = useUser();
  const [nom, setNom] = useState('');
  const [numeroSerie, setNumeroSerie] = useState('');
  const [commentaire, setCommentaire] = useState('');
  const [equipmentType, setEquipmentType] = useState('');
  const navigate = useNavigate();


  const handleSubmit = async (e) => {
  e.preventDefault();

  // Construisez l'objet représentant l'équipement
  const equipement = {
    type: equipmentType,
    nom,
    numeroSerie,
    commentaire,
  };

  console.table(equipement);
  
// Dans votre fonction handleSubmit
const gestionnaire = {
  prenom: user.prenom,
  nom: user.nom,
  numeroTelephone: user.numeroTelephone,
  email: user.email,
 };

  try {
    // Effectuez la requête POST vers le serveur
    console.log(JSON.stringify({ equipement, gestionnaire }));
    const response = await fetch('http://91.160.59.210:45677/equipements/add', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ equipement, gestionnaire }),
    });

    // Vérifiez si la requête a réussi (statut 2xx)
    if (response.ok) {
      // Réinitialisez les champs après la soumission
      setNom('');
      setNumeroSerie('');
      setCommentaire('');
    } else {
      // Si la requête échoue, loggez le statut de la réponse
      console.error(`Échec de l'ajout de l'équipement. Statut: ${response.status}`);
    }
  } catch (error) {
    // Loggez toute erreur survenue lors de la requête
    console.error('Erreur lors de la requête:', error);
  }
  navigate('/private/active-equipements');
};

const handleEquipmentTypeChange = (event) => {
  setEquipmentType(event.target.value);
 }

  // Rendu du composant
  return (
  <div className='main-panel'>
    <h1>Ajout d'un équipement</h1>
    <div className="container">
      <form className="form" onSubmit={handleSubmit}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <label htmlFor="equipmentType" style={{ marginRight: '10px' }}>Type d'équipement :</label>
          <select
            id="equipmentType"
            value={equipmentType}
            onChange={handleEquipmentTypeChange}
          >
            <option value="">Sélectionnez un type d'équipement</option>
            <option value="Connecteur">Connecteur</option>
            <option value="Casque">Casque</option>
            <option value="Dégaine">Dégaine</option>
            <option value="Harnais">Harnais</option>
            <option value="Corde">Corde</option>
            <option value="Assureur">Assureur</option>
          </select>
        </div>
        <br />
        <label>
          Nom:
          <input className='form-field' type="text" value={nom} onChange={(e) => setNom(e.target.value)} required />
        </label>
        <br />
        <label>
          Numéro de Série:
          <input className='form-field' type="text" value={numeroSerie} onChange={(e) => setNumeroSerie(e.target.value)} required />
        </label>
        <br />
        <label>
          Commentaire:
          <br />
          <textarea
            className='form-field'
            value={commentaire}
            onChange={(e) => setCommentaire(e.target.value)}
            maxLength={200}
            rows={4}
            required
          />
        </label>
        <br />
        <br />
        <button type="submit">Ajouter l'équipement</button>
      </form>
    </div>
  </div>
);
};
// Exportez le composant pour pouvoir l'utiliser ailleurs dans votre application
export default AddEquipment;
