import React, { useState } from 'react';
import { useUser } from '../user-management/UserContext';

const UserProfilePage = () => {
  const { user } = useUser();
  const [passwordState, setPasswordState] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  const handleChange = (event) => {
    setPasswordState({ ...passwordState, [event.target.name]: event.target.value });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (passwordState.newPassword !== passwordState.confirmPassword) {
      alert("Les nouveaux mots de passe ne correspondent pas.");
      return;
    }
    if (passwordState.oldPassword !== user.password) {
        alert("L'ancien mot de passe est incorrect");
        return;
      }
    
    updatePassword();
  };

  const updatePassword = async () => {
    try {
      const response = await fetch('http://91.160.59.210:45677/auth/update-password', { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: user.gestionnaireId, 
          oldPassword: passwordState.oldPassword,
          newPassword: passwordState.newPassword,
        }),
      });
  
      if (!response.ok) {
        throw new Error('Erreur lors de la mise à jour du mot de passe.');
      }
  
      alert('Mot de passe mis à jour avec succès.');
    } catch (error) {
      console.error(error);
      alert('Une erreur s\'est produite lors de la mise à jour du mot de passe.');
    }
  };

  return (
    <div className='container'>
      <div className='form'>
        <h1>Informations du profil</h1>
        <p>Nom: {user.nom}</p>
        <p>Prenom: {user.prenom}</p>
        <p>Email: {user.email}</p>
        <p>Téléphone: {user.numeroTelephone}</p>

        <div className='form'>
          <h4>Modification du mot de passe</h4>
          <form onSubmit={handleSubmit}>
            <label htmlFor="oldPassword">Ancien mot de passe:</label>
            <input type="password" id="oldPassword" name="oldPassword" onChange={handleChange} required />
            <br/>
            <label htmlFor="newPassword">Nouveau mot de passe:</label>
            <input type="password" id="newPassword" name="newPassword" onChange={handleChange} required />
            <br/>
            <label htmlFor="confirmPassword">Confirmer le nouveau mot de passe:</label>
            <input type="password" id="confirmPassword" name="confirmPassword" onChange={handleChange} required />
            <br/>
            <button type="submit">Mettre à jour le mot de passe</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserProfilePage;
