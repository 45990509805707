import React, { useState } from 'react';
import axios from 'axios';
import { useUser } from './UserContext';
import './Register.css';
import { useNavigate } from 'react-router-dom';


const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { login } = useUser();
  const navigate = useNavigate();

  const handleLogin = async () => {
  try {
    const response = await axios.post('http://91.160.59.210:45677/auth/login', {
      email: email.toLowerCase(),
      password,
    });

    if (response.status === 200) {
      // Connexion réussie, stocker les informations de l'utilisateur dans le localStorage
	  console.log(response.data);
      login(response.data)
      console.log("Connexion réussie");
	  navigate('/private/active-equipements');
    } else {
      // Si le statut n'est pas 200, traiter comme une erreur
      throw new Error(`Réponse avec un statut non géré: ${response.status}`);
    }
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      // Si c'est une erreur Axios avec une réponse
      const status = error.response.status;

      if (status === 401) {
        // Si le statut est 401, les identifiants sont incorrects
        setErrorMessage('Nom d\'utilisateur ou mot de passe incorrect');
      } else {
        // Gérer d'autres erreurs ici si nécessaire
        console.error(`Erreur avec le statut: ${status}`);
        setErrorMessage('Une erreur s\'est produite lors de la connexion.');
      }
    } else {
      // Gérer d'autres types d'erreurs
      console.error(`Erreur lors de la connexion : ${error.message}`);
      setErrorMessage('Une erreur s\'est produite lors de la connexion.');
    }
  }
};


  return (
    <div>
    <div className='container'>
      <div className='form'>
        <label>Email:</label>
        <br />
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <br />
        <label>Mot de passe:</label>
        <br />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <br />
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        <button onClick={handleLogin}>Se connecter</button>
        <p>Vous n'avez pas de compte ? <a href="/register">Créer un compte</a></p>
      </div>
    </div>
    </div>
  );
};

export default Login;
