import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Utils from '../common/Utils';

const EquipmentCard = ({ equipment }) => {
  return (
    <div className="card mb-3">
      <div className="card-header">Détails de l'équipement</div>
      <ul className="list-group list-group-flush">
        <li className="list-group-item"><strong>Nom :</strong> {equipment.nom}</li>
        <li className="list-group-item"><strong>Type :</strong> {equipment.type}</li>
        <li className="list-group-item"><strong>Numéro de série :</strong> {equipment.numeroSerie}</li>
        <li className="list-group-item"><strong>Date de validité :</strong> {Utils.formatDateString(equipment.dateValidite)}</li>
      </ul>
    </div>
  );
};

const EquipementDetails = () => {
  const { equipementId } = useParams(); 
  const [equipement, setEquipement] = useState({});
  const [evenements, setEvenements] = useState([]);

  useEffect(() => {
    // Récupérer les détails de l'équipement
    fetch(`http://91.160.59.210:45677/equipements/${equipementId}`)
     .then(response => response.json())
     .then(data => {
       console.log('Equipement data:', data);
       setEquipement(data);
     });

    // Récupérer les événements liés à l'équipement
    fetch(`http://91.160.59.210:45677/events/${equipementId}`)
     .then(response => response.json())
     .then(data => {
       console.log('Events data:', data);
       setEvenements(data);
     });
  }, [equipementId]);

  return (
    <div className='main-panel'>
      <h1>Détails de l'équipement</h1>
      <EquipmentCard equipment={equipement} />
      <h2>Événements liés :</h2>
      <table>
        <thead>
            <tr>
            <th>Action</th>
            <th>Raison</th>
            <th>Date de l'événement</th>
            <th>Gestionnaire auteur de l'événement</th>
            </tr>
        </thead>
        <tbody>
            {Array.isArray(evenements) && evenements.sort((a, b) => new Date(b.dateEvent) - new Date(a.dateEvent)).map((event, index) => (
            <tr key={index}>
                <td>{event.action}</td>
                <td>{event.raison}</td>
                <td>{new Date(event.dateEvent).toLocaleDateString()}</td>
                <td>{event.gestionnaire.prenom + " " + event.gestionnaire.nom}</td>
            </tr>
            ))}
        </tbody>
        </table>
    </div>
  );
}

export default EquipementDetails;
