// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
 background-color: black;
 display: flex;
 align-items: center;
 padding: 10px;
}

.userInfo {
 text-align: right;
 color: white;
}

.brand {
 color: white;
 margin-right: auto; 
 font-weight: bold;
}

.navbar ul {
 display: flex; /* Aligne les éléments horizontalement */
 margin: 0; /* Supprime la marge par défaut de la liste */
 padding: 0; /* Supprime le remplissage par défaut de la liste */
}

.navbar li {
 margin-left: 10px; /* Ajoute un espace entre les éléments <li> */
 list-style: none; /* Supprime les puces des éléments <li> */
}

/* Style pour chaque lien <a> à l'intérieur d'un élément <li> */
.navbar a {
 color: white;
 text-decoration: none; /* Supprime le soulignement par défaut du lien */
 padding: 10px;
 border-radius: 5px;
 cursor: pointer;
}

/* Style au survol de chaque lien */
.navbar a:hover {
 background-color: #45a049; /* Légèrement plus foncé au survol */
}
`, "",{"version":3,"sources":["webpack://./src/common/NavigationBar.css"],"names":[],"mappings":"AAAA;CACC,uBAAuB;CACvB,aAAa;CACb,mBAAmB;CACnB,aAAa;AACd;;AAEA;CACC,iBAAiB;CACjB,YAAY;AACb;;AAEA;CACC,YAAY;CACZ,kBAAkB;CAClB,iBAAiB;AAClB;;AAEA;CACC,aAAa,EAAE,wCAAwC;CACvD,SAAS,EAAE,6CAA6C;CACxD,UAAU,EAAE,mDAAmD;AAChE;;AAEA;CACC,iBAAiB,EAAE,6CAA6C;CAChE,gBAAgB,EAAE,yCAAyC;AAC5D;;AAEA,+DAA+D;AAC/D;CACC,YAAY;CACZ,qBAAqB,EAAE,gDAAgD;CACvE,aAAa;CACb,kBAAkB;CAClB,eAAe;AAChB;;AAEA,mCAAmC;AACnC;CACC,yBAAyB,EAAE,oCAAoC;AAChE","sourcesContent":[".navbar {\n background-color: black;\n display: flex;\n align-items: center;\n padding: 10px;\n}\n\n.userInfo {\n text-align: right;\n color: white;\n}\n\n.brand {\n color: white;\n margin-right: auto; \n font-weight: bold;\n}\n\n.navbar ul {\n display: flex; /* Aligne les éléments horizontalement */\n margin: 0; /* Supprime la marge par défaut de la liste */\n padding: 0; /* Supprime le remplissage par défaut de la liste */\n}\n\n.navbar li {\n margin-left: 10px; /* Ajoute un espace entre les éléments <li> */\n list-style: none; /* Supprime les puces des éléments <li> */\n}\n\n/* Style pour chaque lien <a> à l'intérieur d'un élément <li> */\n.navbar a {\n color: white;\n text-decoration: none; /* Supprime le soulignement par défaut du lien */\n padding: 10px;\n border-radius: 5px;\n cursor: pointer;\n}\n\n/* Style au survol de chaque lien */\n.navbar a:hover {\n background-color: #45a049; /* Légèrement plus foncé au survol */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
