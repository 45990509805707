import React, { useState, useEffect } from 'react';
import '../common/styles.css';
import EquipementsTable from './EquipementsTable'; 
import { useNavigate } from 'react-router-dom';

const ActiveEquipementsComponent = () => {

  const navigate = useNavigate();

  const handleAjouterClick = () => {
    navigate('/private/add-equipment');
  };

  return (
    <div>
      <h1>Liste des équipements en service</h1>
      <button className="registerButton" onClick={handleAjouterClick}>Ajouter un Équipement</button>
      <EquipementsTable url={'http://91.160.59.210:45677/equipements/active'} />
    </div>
  );
};

export default ActiveEquipementsComponent;