import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useUser } from '../user-management/UserContext';

const PrivateRoute = () => {
 const { user } = useUser();

 return user ? <Outlet /> : <Navigate to="/connection" />;

};

export default PrivateRoute;
