import React, { useState, useEffect } from 'react';
import '../common/styles.css';
import EquipementsTable from './EquipementsTable'; 

const ToValidateEquipementsComponent = () => {

  return (
    <div>
      <h1>Liste des équipements à valider</h1>
      <EquipementsTable url={'http://91.160.59.210:45677/equipements/to-validate'} />
    </div>
  );
};

export default ToValidateEquipementsComponent;