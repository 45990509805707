import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const EquipementValidation = ({ equipementId }) => {
    const { id } = useParams();
    const [equipement, setEquipement] = useState(null);
    const [validation, setValidation] = useState({/*TODO INIT VALIDATION STATUS*/});


    useEffect(() => {
        const fetchEquipement = async () => {
          try {
            const response = await axios.get(`http://91.160.59.210:45677/equipements/${id}`);
            setEquipement(response.data);
          } catch (error) {
            console.error('Erreur lors de la récupération des données de l\'équipement:', error);
          }
        };
    
        fetchEquipement();
     }, [id]);

    const handleValidationChange = (e) => {
        setValidation({
        ...validation,
        [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
    e.preventDefault();
    try {
       const response = await fetch('http://91.160.59.210:45677/equipements/validate', {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json',
         },
         body: JSON.stringify({
           equipementId: equipement.id,
           validation,
         }),
       });
   
       if (response.ok) {
         // Gérez le succès ici
       } else {
         // Gérez l'erreur ici
       }
    } catch (error) {
       // Gérez l'erreur ici
    }
    };

    return (
        equipement && <div>
        {/* Affichez les informations de l'équipement */}
        <form onSubmit={handleSubmit}>
            {/* Affichez les boutons radio en fonction du type de l'équipement */}
            {equipement.type === 'Harnais' && (
            <>
                {/* Boutons radio pour Harnais */}
            </>
            )}
            {/* Ajoutez des conditions similaires pour les autres types d'équipements */}
            <input type="date" onChange={(e) => setValidation({ ...validation, date: e.target.value })} />
            <button type="submit">Valider</button>
        </form>
        </div>
    );
};

export default EquipementValidation;