import React from 'react';
import { Link } from 'react-router-dom';
import { useUser } from '../user-management/UserContext';
import './NavigationBar.css';
import { useNavigate } from 'react-router-dom';


const Navbar = () => {

  const { user, logout } = useUser(); 
  const navigate = useNavigate();  
  
  const handleLogout = () => {
    navigate('/connection');
  };

  return (
    <nav className="navbar">
      <ul>
        <li className="brand"><Link to="/private/active-equipements">AMVY - EPI</Link></li>
        {user ? (
          <>
			      <li><Link to="/private/active-equipements">Équipements actifs</Link></li>
            <li><Link to="/private/to-validate-equipements">Équipements à valider</Link></li>
            <li><Link to="/private/inactive-equipements">Équipements inactifs</Link></li>
            <li className="userInfo"><Link to="/private/profile">Mon profil</Link></li>
            <li className="userInfo">Bonjour, {user.prenom} {user.nom}</li>
            <li className="userInfo"><button onClick={() => { logout(); handleLogout(); }}>Déconnexion</button>
</li>
          </>
        ) : (
          <>
            <li ><Link to="/register">S'enregistrer</Link></li>
            <li ><Link to="/connection">Se connecter</Link></li>	
          </>
        )}
      </ul>
    </nav>
  );
};

export default Navbar;
