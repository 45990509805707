import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from '../user-management/Login';
import RegisterUser from '../user-management/RegisterUser';
import ActiveEquipementsComponent from '../equipement-management/ActiveEquipementsComponent';
import ToValidateEquipementsComponent from '../equipement-management/ToValidateEquipementsComponent';
import InactiveEquipementsComponent from '../equipement-management/InactiveEquipementsComponent';
import NavigationBar from './NavigationBar';
import AddEquipment from '../equipement-management/AddEpi';
import PrivateRoute from './PrivateRoute';
import EquipementValidation from '../equipement-management/EquipementValidation';
import EquipementDetails from '../equipement-management/EquipementDetails'; // Assurez-vous que le chemin d'importation est correct
import UserProfilePage from '../user-management/UserProfilePage';



const App = () => {
 return (
 <Router>
   <NavigationBar/>
   <Routes>
     <Route path="/register" element={<RegisterUser />} />
     <Route path="/connection" element={<Login />} />
     <Route path="/private/*" element={<PrivateRoute />}>
       <Route path="active-equipements" element={<ActiveEquipementsComponent />} />
       <Route path="to-validate-equipements" element={<ToValidateEquipementsComponent />} />
       <Route path="inactive-equipements" element={<InactiveEquipementsComponent />} />
       <Route path="add-equipment" element={<AddEquipment />} />
       <Route path="equipement-validation/:id" element={<EquipementValidation />} />
       <Route path="equipement/:equipementId" element={<EquipementDetails />} />
       <Route path="profile" element={<UserProfilePage />} />

      </Route>
   </Routes>
 </Router>
 );
};

export default App;
