import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {useLocation} from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../user-management/UserContext'; 
import Utils from '../common/Utils';


const EquipementRow = ({equipement, onModifierClick, onMettreAuRebusClick, onSupprimerClick, refreshCallback}) => {
    const [motifMiseAuRebus, setMotifMiseAuRebus] = useState('');
    const [latestEvent, setLatestEvent] = useState(null);
    const [validity, setValidity] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const navigate = useNavigate();
    const {numeroSerie, nom, commentaire, dateAjout, misAuRebus} = equipement;
    const location = useLocation();
    const { user } = useUser();
    const [editedValues, setEditedValues] = useState({
        numeroSerie: equipement.numeroSerie,
        nom: equipement.nom,
        commentaire: equipement.commentaire,
    });


    const isInactiveEquipementPage = location.pathname === '/private/inactive-equipements';

    useEffect(() => {
        const getLatestEvent = async () => {
            const latestEventData = await fetchLatestEvent(equipement.equipementId);
            setLatestEvent(latestEventData);
        };
        console.table(equipement)
        getLatestEvent();
    }, [equipement.equipementId]);

    const fetchLatestEvent = async (equipementId) => {
        try {
            const response = await axios.get(`http://91.160.59.210:45677/events/last/${equipementId}`);
            return response.data;
        } catch (error) {
            console.error(`Erreur lors de la récupération du dernier événement : ${error.message}`);
            return null;
        }
    };


    const getEventAction = () => {
        if (latestEvent) {
            return `${latestEvent.action}`;
        }
        return 'N/A';
    };

    //METHODES DE MISE AU REBUS

    const mettreAuRebus = async (equipementId, motif, gestionnaireRebus) => {
        try {
            const response = await axios.post('http://91.160.59.210:45677/equipements/rebus', {
                equipement,
                raison: motif,
                gestionnaireRebus,
            });

            if (response.status === 200) {
                return true;
            } else {
                console.error(`Erreur lors de la mise au rebut. Statut: ${response.status}`);
                return false;
            }
        } catch (error) {
            console.error(`Erreur lors de la mise au rebut : ${error.message}`);
            return false;
        }
    };

    const handleMettreAuRebusClick = async () => {
        const confirmation = window.confirm('Êtes-vous certain de vouloir mettre au rebut cet équipement ?');
    
        if (!confirmation) return;
    
        const motif = prompt('Veuillez saisir le motif de la mise au rebut :');
        if (motif === null) {
            alert('Vous devez saisir un motif pour mettre au rebut.');
            return;
        }
    
        if (!user) {
            console.error('Utilisateur non connecté ou indéfini');
            return;
        }
    
        const gestionnaire = {
            prenom: user.prenom,
            nom: user.nom,
            numeroTelephone: user.numeroTelephone,
            email: user.email,
        };
    
        try {
            const miseAuRebusSuccess = await mettreAuRebus(equipement.equipementId, motif, gestionnaire);
    
            if (miseAuRebusSuccess) {
                setMotifMiseAuRebus(motif);
                onMettreAuRebusClick(numeroSerie);
            } else {
                console.error('Échec de la mise au rebut de l\'équipement');
            }
        } catch (error) {
            console.error(`Erreur lors de la mise au rebut de l'équipement : ${error.message}`);
        }
    };
    
    const handleRebusClick = () => {
        console.log(location.pathname)
        if (isInactiveEquipementPage) {
            handleSortirRebusClick();
        } else {
            handleMettreAuRebusClick();
        }
        refreshCallback();
    };


    //METHODES DE SORTIE DE REBUS

    const handleSortirRebusClick = async () => {
        const confirmation = window.confirm('Êtes-vous certain de vouloir sortir cet équipement du rebus ?');
    
        if (!confirmation) return;
    
        const motif = prompt('Veuillez saisir le motif de la sortie du rebus :');
        if (motif === null) {
            alert('Vous devez saisir un motif pour sortir du rebus.');
            return;
        }
    
        if (!user) {
            console.error('Utilisateur non connecté ou indéfini');
            return;
        }
    
        const gestionnaire = {
            prenom: user.prenom,
            nom: user.nom,
            numeroTelephone: user.numeroTelephone,
            email: user.email,
        };
    
        try {
            // Appeler la méthode pour sortir du rebus
            const sortieRebusSuccess = await sortirDuRebus(equipement.equipementId, motif, gestionnaire);
    
            if (sortieRebusSuccess) {
                setMotifMiseAuRebus(motif);
                onMettreAuRebusClick(numeroSerie);
            } else {
                console.error('Échec de la sortie de l\'équipement du rebus');
            }
        } catch (error) {
            console.error(`Erreur lors de la sortie du rebus : ${error.message}`);
        }
    };
    
    const sortirDuRebus = async (equipementId, motif, gestionnaireSortieRebus) => {
        try {

            const response = await axios.post('http://91.160.59.210:45677/equipements/annuler-rebus', {
                equipement,
                raison: motif,
                gestionnaireRebus: gestionnaireSortieRebus,
            });
    
            if (response.status === 200) {
                return true;
            } else {
                console.error(`Erreur lors de la sortie du rebus. Statut: ${response.status}`);
                return false;
            }
        } catch (error) {
            console.error(`Erreur lors de la sortie du rebus : ${error.message}`);
            return false;
        }
    };
    

    //METHODES DE VALIDATION

    const validerEquipement = async (idEq) => {
        const confirmation = window.confirm('Êtes-vous sûr de vouloir valider cet équipement?');
        if (!user) {
            console.error('Utilisateur non connecté ou indéfini');
            return;
        }
        const idGes = user.gestionnaireId;
        console.log(user.gestionnaireId)
    
        if (confirmation) {
    
            if (idEq === undefined) {
                console.error('ID de l\'équipement  est indéfini');
                return; 
            } else if (!idGes) {
                console.error('ID du gestionnaire  est indéfini : ' + idGes);
                return; 
            }
    
            try {
                const response = await fetch(`http://91.160.59.210:45677/equipements/validate/${idEq}?idGes=${user.gestionnaireId}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
        
                if (response.ok) {
                    console.log('Équipement validé avec succès');
                    refreshCallback(); 
                } else {
                    console.error('Erreur lors de la validation de l\'équipement');
                }
            } catch (error) {
                console.error(`Erreur lors de la validation de l'équipement : ${error.message}`);
            }
        }
    }

    const getEventDate = () => {
        if (latestEvent) {
            return Utils.formatDateString(latestEvent.dateEvent);
        }
        return 'N/A';
    };

    const getValidityDate = () => {
        if (equipement) {
            return Utils.formatDateString(equipement.dateValidite);
        }
        return 'N/A';
    }

    const handleEditClick = () => {
        setIsEditMode(true);
    };

    const handleSaveClick = async () => {
        try {
            // Envoyer les modifications au backend
            await handleUpdateEquipement();

            // Appeler la fonction de rappel pour actualiser la liste
            refreshCallback();

            // Désactiver le mode édition
            setIsEditMode(false);
        } catch (error) {
            console.error(`Erreur lors de la sauvegarde des modifications : ${error.message}`);
            // Gérer les erreurs en conséquence (affichage d'une alerte, etc.)
        }
    };

    const handleCancelClick = () => {
        // Annuler les modifications en restaurant les valeurs d'origine
        setEditedValues({
            numeroSerie: equipement.numeroSerie,
            nom: equipement.nom,
            commentaire: equipement.commentaire,
        });
        setIsEditMode(false);
    };

    const handleInputChange = (e) => {
        // Mettre à jour les valeurs modifiées lorsque l'utilisateur modifie les champs
        const {name, value} = e.target;
        setEditedValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleUpdateEquipement = async () => {
        if (!user) {
            console.error('Utilisateur non connecté ou indéfini');
            return;
        }
    
        // Préparer les données pour la mise à jour
        const equipementUpdateDTO = {
            equipement: {
                ...equipement,
                numeroSerie: editedValues.numeroSerie,
                nom: editedValues.nom,
                commentaire: editedValues.commentaire,
            },
            gestionnaire: {
                id: user,
                prenom: user.prenom,
                nom: user.nom,
                numeroTelephone: user.numeroTelephone,
                email: user.email,
            },
        };
    
        console.log('Données de mise à jour envoyées :', equipementUpdateDTO);
    
        try {
            // Envoyer la demande de mise à jour au backend
            const response = await axios.put('http://91.160.59.210:45677/equipements/update', equipementUpdateDTO);
    
            if (response.status === 200) {
                console.log('Équipement mis à jour avec succès');
                refreshCallback();
            } else {
                console.error(`Erreur lors de la mise à jour de l'équipement. Statut: ${response.status}`);
            }
        } catch (error) {
            console.error(`Erreur lors de la mise à jour de l'équipement : ${error.message}`);
        }
    };
    

    async function askForBorrowDetails() {
        let borrowerName = prompt("Veuillez entrer le nom du preneur");
        if (borrowerName) {
            let borrowerSurname = prompt("Veuillez entrer le prénom du preneur");
            if (borrowerSurname) {
                let dueDate = prompt("Veuillez entrer la date de rendu prévue (format YYYY-MM-DD)");
                if (dueDate) {
                    const borrowData = {
                        equipementId: equipement.equipementId,
                        borrowerName,
                        borrowerSurname,
                        dueDate
                    };
    
                    try {
                        //const response = await axios.post('http://91.160.59.210:45677/equipements/borrow?idGes=${user.gestionnaireId}', borrowData);
                        const response = await axios.post(`http://91.160.59.210:45677/equipements/borrow?idGes=${user.gestionnaireId}`, borrowData);

                        if (response.status === 200) {
                            alert('Emprunt enregistré avec succès.');
                            refreshCallback(); // Rafraîchir la liste des équipements ou effectuer une autre action
                        } else {
                            alert('Erreur lors de l\'enregistrement de l\'emprunt.');
                        }
                    } catch (error) {
                        console.error(`Erreur lors de l'enregistrement de l'emprunt : ${error.message}`);
                        alert('Erreur lors de l\'enregistrement de l\'emprunt.');
                    }
                }
            }
        }
    }

    return (
        <tr>
            <td>{equipement.type}</td>
            <td 
                onClick={() => navigate(`/private/equipement/${equipement.equipementId}`)}
                style={{
                    color: !isEditMode ? 'blue' : 'inherit'
                }}
            >
                {isEditMode ? 
                    <input 
                        type="text" 
                        name="numeroSerie" 
                        value={editedValues.numeroSerie}
                        onChange={handleInputChange}
                    /> 
                    : numeroSerie}
            </td>
            <td>{isEditMode ? 
                <input 
                    type="text" 
                    name="nom" 
                    value={editedValues.nom} 
                    onChange={handleInputChange}
                /> 
                : nom}
            </td>
            <td>{isEditMode ? 
                <input 
                    type="text" 
                    name="commentaire" 
                    value={editedValues.commentaire}
                    onChange={handleInputChange}
                /> 
                : commentaire}
            </td>
            <td>{getValidityDate()}</td>
            <td>{getEventDate()}</td>
            <td>{getEventAction()}</td>
            <td>{latestEvent ? `${latestEvent.gestionnaire.prenom} ${latestEvent.gestionnaire.nom}` : 'N/A'}</td>
            <td>
                {location.pathname === '/private/to-validate-equipements' && (
                    <button onClick={() => validerEquipement(equipement.equipementId)}>Valider</button>
                )}
    
                {isEditMode ? (
                    <>
                        <button onClick={handleSaveClick}>Valider les modifications</button>
                        <button onClick={handleCancelClick}>Annuler</button>
                    </>
                ) : (
                    <button onClick={handleEditClick}>Modifier</button>
                )}
                
                <button onClick={handleRebusClick}>
                    {isInactiveEquipementPage ? 'Sortir du Rebus' : 'Mettre au Rebus'}
                </button>
            </td>
        </tr>
    );
    
};

export default EquipementRow;


/*

{isInactiveEquipementPage ? (
                    <button onClick={() => onSupprimerClick(numeroSerie)}>Supprimer</button>
                ) : null

                }


*/
