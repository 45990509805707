import React, { useState, useEffect } from 'react';
import '../common/styles.css';
import EquipementsTable from './EquipementsTable'; 

const InactiveEquipementsComponent = () => {

  return (
    <div>
      <h1>Liste des équipements mis au rebus</h1>
      <EquipementsTable url={'http://91.160.59.210:45677/equipements/inactive'} />
    </div>
  );
};

export default InactiveEquipementsComponent;