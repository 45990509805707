// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* register.css */

.container {
  margin-top: 2%;
  display: flex;
  justify-content: center ;
  align-items: center;
  /*height: 70vh;*/
  margin-top: 5%;
}

.form {
  text-align: left;
  font-size: 1.2em;
}

.form-field {
  margin-left: 0%;
  margin-right: 2%;
}


button:hover {
  background-color: #45a049;
}


.form {
  background-color: rgb(255, 255, 255);
  position: center;
  padding:  20px;
  box-shadow:  0  0  10px rgba(0,  0,  0,  0.1);
  width:  80%;
}

.form label {
  display: block;
  margin-bottom:  10px;
}

.form input,
.form textarea {
  width:  95%;
  padding:  10px;
  margin-bottom:  20px;
  border:  1px solid #ccc;
  border-radius:  3px;
}

.form button {
  background-color: #007bff;
  margin-left: 84%;
  color: white;
  border: none;
  border-radius:  3px;
  cursor: pointer;
}

.form button:hover {
  background-color: #0056b3;
}`, "",{"version":3,"sources":["webpack://./src/user-management/Register.css"],"names":[],"mappings":"AAAA,iBAAiB;;AAEjB;EACE,cAAc;EACd,aAAa;EACb,wBAAwB;EACxB,mBAAmB;EACnB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;;AAGA;EACE,yBAAyB;AAC3B;;;AAGA;EACE,oCAAoC;EACpC,gBAAgB;EAChB,cAAc;EACd,6CAA6C;EAC7C,WAAW;AACb;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;;EAEE,WAAW;EACX,cAAc;EACd,oBAAoB;EACpB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,YAAY;EACZ,YAAY;EACZ,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["/* register.css */\n\n.container {\n  margin-top: 2%;\n  display: flex;\n  justify-content: center ;\n  align-items: center;\n  /*height: 70vh;*/\n  margin-top: 5%;\n}\n\n.form {\n  text-align: left;\n  font-size: 1.2em;\n}\n\n.form-field {\n  margin-left: 0%;\n  margin-right: 2%;\n}\n\n\nbutton:hover {\n  background-color: #45a049;\n}\n\n\n.form {\n  background-color: rgb(255, 255, 255);\n  position: center;\n  padding:  20px;\n  box-shadow:  0  0  10px rgba(0,  0,  0,  0.1);\n  width:  80%;\n}\n\n.form label {\n  display: block;\n  margin-bottom:  10px;\n}\n\n.form input,\n.form textarea {\n  width:  95%;\n  padding:  10px;\n  margin-bottom:  20px;\n  border:  1px solid #ccc;\n  border-radius:  3px;\n}\n\n.form button {\n  background-color: #007bff;\n  margin-left: 84%;\n  color: white;\n  border: none;\n  border-radius:  3px;\n  cursor: pointer;\n}\n\n.form button:hover {\n  background-color: #0056b3;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
