import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Register.css';


const RegisterUser = () => {
  const [prenom, setFirstName] = useState('');
  const [nom, setLastName] = useState('');
  const [numeroTelephone, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

const handleSubmit = async (e) => {
  e.preventDefault();

  // Construisez l'objet représentant l'utilisateur
  const user = {
    prenom,
    nom,
    numeroTelephone,
    email: email.toLowerCase(),
	password,
  };

  try {
    // Effectuez la requête POST vers le serveur
	console.log(JSON.stringify(user));
    const response = await fetch('http://91.160.59.210:45677/auth/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
    });

    // Vérifiez si la requête a réussi (statut 2xx)
    if (response.ok) {
      console.log('Utilisateur enregistré avec succès!');
      
      // Réinitialisez les champs après la soumission
      setFirstName('');
      setLastName('');
      setPhoneNumber('');
      setEmail('');
	    navigate('/connection');
    } else {
      // Si la requête échoue, loggez le statut de la réponse
      console.error(`Échec de l'enregistrement de l'utilisateur. Statut: ${response.status}`);
    }
  } catch (error) {
    // Loggez toute erreur survenue lors de la requête
    console.error('Erreur lors de la requête:', error);
  }
};

	

  // Rendu du composant
  return (
    <div>
      <h1>Enregistrement d'utilisateur</h1>
      <div className="container">
      <form className="form" onSubmit={handleSubmit}>
        <label>
          Nom:
          <input type="text" value={nom} onChange={(e) => setLastName(e.target.value)} required />
        </label>
        <br />
        <label>
          Prénom:
          <input type="text" value={prenom} onChange={(e) => setFirstName(e.target.value)} required />
        </label>
        <br />
        <label>
          Numéro de téléphone:
          <input type="tel" value={numeroTelephone} onChange={(e) => setPhoneNumber(e.target.value)} required />
        </label>
        <br />
        <label>
          Email:
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        </label>
		    <label>
          Mot de passe:
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
        </label>
        <br />
        <button type="submit">Enregistrer</button>
      </form>
      </div>
    </div>
  );
};

// Exportez le composant pour pouvoir l'utiliser ailleurs dans votre application
export default RegisterUser;
