const Utils = {
    formatDateString: (rawDate) => {
      if (!rawDate) return '';
      const options = { day: 'numeric', month: 'numeric', year: 'numeric' };
      const formattedDate = new Date(rawDate).toLocaleDateString(undefined, options);
      return formattedDate;
    }
  };
  
  export default Utils;
  